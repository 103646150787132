import { FC } from "react";

import Link from "next/link";
import { useRouter } from "next/router";

import { Button } from "@busbud/design-system-components";
import { AngleDownSM } from "@busbud/design-system-components/dist/Icons";
import Stack from "@busbud/design-system-components/dist/Stack";

import { localesToPubwebLocale } from "../constants/i18n";

import { LocalePicker } from "./locale-picker";

type Props = {
  translations: NavigationTranslationsFragment &
    LocalePickerTranslationsFragment;
};

export const MenuDesktop: FC<Props> = ({ translations }) => {
  const { locale } = useRouter();
  const busbud_locale =
    localesToPubwebLocale[locale as keyof typeof localesToPubwebLocale] || "en";

  return (
    <Stack spacing={1}>
      <LocalePicker translations={translations}>
        <LocalePicker.Button
          variant="text"
          size="sm"
          endIcon={<AngleDownSM />}
        />
      </LocalePicker>
      <Link
        prefetch={false}
        href={`https://www.busbud.com/${busbud_locale}`}
        legacyBehavior
      >
        <Button variant="text" color="tertiary" size="sm">
          {translations?.navItemBusbud}
        </Button>
      </Link>
    </Stack>
  );
};
