import { FC } from "react";

import Link from "next/link";

import BrandLogo from "@busbud/design-system-components/dist/BrandLogo";

import { useBreakpoint } from "@hooks/useBreakpoint";

export const HomeLink: FC = () => {
  const isSmUp = useBreakpoint("sm");

  return (
    <Link prefetch={false} href="/">
      <BrandLogo size={isSmUp ? "md" : "sm"} />
    </Link>
  );
};
